import axios from 'axios';
import { domain } from './domain'

export const getRestaurant = (token,id) => {
    const requestOptions = {
        method : 'POST',
        headers : { 'Authorization' : 'Bearer ' + token },
        data : { admins : [id]}
    }
    return axios(domain+'/company/get',requestOptions)
    .then( response => { return response })
    .catch( error => { return error })
}

export const updateRestaurant = (token,data) => {
    const requestOptions = {
        method : 'PUT',
        headers : { 
            'Authorization' : 'Bearer ' + token,
            'Content-type' : 'application/json'
        },
        data
    }
    return axios(domain+'/restaurant',requestOptions)
    .then( response => { return response })
    .catch( error => { return error })
}
