import React, { useEffect } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import './scss/style.scss';
import { auth } from './_services/user';
import { useAtom } from 'jotai';
import { userAtom } from './_atoms/user';
import { getRestaurant } from './_services/restaurantServices';
import { companyAtom } from './_atoms/company';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const App = props => {
  const [user, setUser] = useAtom(userAtom)
  const { token } = props.cookies.cookies
  const [, setCompany] = useAtom(companyAtom)
  const { loggedIn } = user

  useEffect(() => {
    if (token && token !== '') {
      auth(token).then((res) => {
        if (res.data && res.data.code && res.data.code === 200) {
          setUser({ ...res.data.data, loggedIn: true, token: token });
        } else {
          document.cookie = "token="
        }
      });
    }
  }, [token])

  useEffect(() => {
    if (user && user._id) {
      getRestaurant(token, user._id)
        .then(res2 => {
          if (res2.data && res2.data.code === 200) {
            setCompany(res2.data.data[0])
          }
        })
    }
  }, [user])

  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route exact path="/login" name="Login Page" render={props => { return loggedIn ? <Redirect to='/' /> : <Login {...props} /> }} />
          <Route exact path="/register" name="Register Page" render={props => { return loggedIn ? <Redirect to='/' /> : <Register {...props} /> }} />
          <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
          <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
          <Route path="/" name="Anasayfa" render={props2 => { return loggedIn ? <TheLayout {...props} {...props2} /> : <Redirect to='/login' /> }} />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
}

export default withCookies(App);
